export function AccountOrder() {
  return (
    <>
      <path
        fill="currentColor"
        className="show-on-small"
        d="M11.25 14.853l-.238-.578-.387.16v.418h.625zm17.5 0h.625v-.419l-.387-.159-.238.578zm0 9.78l.266.565.359-.169v-.397h-.625zm-17.5 0h-.625v.396l.359.169.266-.566zM20 28.75l-.266.566.266.125.266-.125L20 28.75zm0-17.5l.238-.578-.238-.098-.238.098.238.578zm3.75 10.552a.625.625 0 001.25 0h-1.25zm4.375-6.95v9.78h1.25v-9.78h-1.25zm-16.25 9.78v-9.78h-1.25v9.78h1.25zm-.891-9.214l8.75 4.118.532-1.13-8.75-4.119-.532 1.131zm17.5 8.649l-8.75 4.117.532 1.131 8.75-4.117-.532-1.131zm-8.218 4.117l-8.75-4.117-.532 1.13 8.75 4.119.532-1.131zm-.891-9.213v9.779h1.25v-9.78h-1.25zm.387-7.143l8.75 3.603.476-1.156-8.75-3.603-.476 1.156zm-8.274 3.603l4.375-1.802-.476-1.155-4.375 1.801.476 1.156zm4.375-1.802l4.375-1.801-.476-1.156-4.375 1.801.476 1.156zm4.403 5.907l4.375-2.059-.532-1.13-4.375 2.058.532 1.131zm4.375-2.059l4.375-2.059-.532-1.13-4.375 2.058.532 1.131zm-9.268-3.854l8.75 3.86.504-1.143-8.75-3.86-.504 1.143zM25 21.802v-4.89h-1.25v4.89H25z"
      ></path>
      <path
        fill="currentColor"
        className="hide-on-small"
        d="M10 14.118l-.38-.925-.62.255v.67h1zm20 0h1v-.67l-.62-.255-.38.925zm0 11.176l.426.905.574-.27v-.635h-1zm-20 0H9v.635l.574.27.426-.905zM20 30l-.426.905.426.2.426-.2L20 30zm0-20l.38-.925L20 8.92l-.38.156L20 10zm4 12.059a1 1 0 102 0h-2zm5-7.941v11.176h2V14.118h-2zM11 25.294V14.118H9v11.176h2zM9.574 15.023l10 4.705.852-1.81-10-4.705-.852 1.81zm20 9.366l-10 4.706.852 1.81 10-4.706-.852-1.81zm-9.148 4.706l-10-4.706-.852 1.81 10 4.706.852-1.81zM19 18.824V30h2V18.823h-2zm.62-7.9l10 4.118.76-1.849-10-4.118-.76 1.85zm-9.24 4.118l5-2.059-.76-1.849-5 2.059.76 1.85zm5-2.059l5-2.058-.76-1.85-5 2.06.76 1.848zm5.046 6.745l5-2.353-.852-1.81-5 2.354.852 1.81zm5-2.353l5-2.352-.852-1.81-5 2.353.852 1.81zm-10.83-4.401l10 4.412.808-1.83-10-4.412-.808 1.83zM26 22.059V16.47h-2v5.588h2z"
      ></path>
    </>
  )
}
