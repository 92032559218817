import { parse } from '@config/theme';
export const langSwitchContainer = parse({
  display: {
    _: 'flex',
    medium: 'none'
  },
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  p: '4'
});
export const actionLink = parse({
  display: 'flex',
  fontFamily: 'body',
  alignItems: 'center',
  mr: '2',
  position: 'relative',
  color: 'shade800'
}, "a6inkxt");

require("./LanguageSwitch.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./LanguageSwitch.styles.ts");