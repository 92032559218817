import HeaderMobileNavLanguageSwitch from './LanguageSwitch'
import * as styles from './SubNav.styles'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { bodyRegularStyle } from '@components/gassan-ui/Typography/linariaStyles'
import { useAuth } from '@lib/hooks/use-auth'
import { cx } from '@linaria/core'
import { useTranslation } from 'next-i18next'
import { FC, HTMLAttributes } from 'react'

type Props = HTMLAttributes<HTMLDivElement> & {
  isVisible: boolean
}

const HeaderMobileSubNav: FC<Props> = ({ isVisible, ...rest }) => {
  const { t } = useTranslation('navigation')
  const { isLoggedIn } = useAuth()

  return (
    <div className={styles.container} data-invisible={!isVisible ? '' : undefined} {...rest}>
      {isLoggedIn ? (
        <LocalizedLink href={t('secondaryNav.myGassan.url')}>
          <a className={cx(bodyRegularStyle, styles.item)}>{t('secondaryNav.myGassan.title')}</a>
        </LocalizedLink>
      ) : (
        <LocalizedLink href={t('account.login.url')}>
          <a className={cx(bodyRegularStyle, styles.item)}>{t('account.login.title')}</a>
        </LocalizedLink>
      )}
      {isLoggedIn && (
        <LocalizedLink href={t('secondaryNav.wishlist.url')}>
          <a className={cx(bodyRegularStyle, styles.item)}>{t('secondaryNav.wishlist.title')}</a>
        </LocalizedLink>
      )}
      <LocalizedLink href={t('secondaryNav.stores.url')}>
        <a className={cx(bodyRegularStyle, styles.item)}>{t('secondaryNav.stores.title')}</a>
      </LocalizedLink>
      <LocalizedLink href={t('secondaryNav.contact.url')}>
        <a className={cx(bodyRegularStyle, styles.item)}>{t('secondaryNav.contact.title')}</a>
      </LocalizedLink>
      <HeaderMobileNavLanguageSwitch />
    </div>
  )
}

export default HeaderMobileSubNav
