import * as styles from './LanguageSwitch.styles'
import { useLocalization } from '@components/Localization'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

const nlFlag = '/logos/flag_nl.png'
const enFlag = '/logos/flag_en.png'

const HeaderMobileNavLanguageSwitch: FC = (props) => {
  const { setLang } = useLocalization()
  const {
    t,
    i18n: { language },
  } = useTranslation('other')
  return (
    <div className={styles.langSwitchContainer}>
      <button
        className={styles.actionLink}
        onClick={() => setLang(language === 'nl' ? 'en' : 'nl')}
      >
        {t('switchTo')}
        <img src={language === 'nl' ? enFlag : nlFlag} alt="" />
      </button>
    </div>
  )
}

export default HeaderMobileNavLanguageSwitch
