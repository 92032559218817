import { NavActiveOptions } from '../HeaderContent';
import LocalizedLink from '@components/Localization/LocalizedLink';
import { linkStyle } from '@components/gassan-ui/Typography/linariaStyles';
import { parse } from '@config/theme';
import { SHOULD_DISPLAY_RCPO_CONTENT } from '@lib/feature-flags';
import { cx } from '@linaria/core';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
type HeaderNavigationMainNavProps = {
  onClickItem: (key: NavActiveOptions) => void;
};
export const HeaderNavigationMainNav: FC<HeaderNavigationMainNavProps> = ({
  onClickItem
}) => {
  const {
    t
  } = useTranslation(['navigation', 'global', 'other']);
  return <div className={parent}>
      <LocalizedLink href="/rolex" passHref>
        <a className={cx(linkStyle, navItem)}>Rolex</a>
      </LocalizedLink>
      <button className={cx(linkStyle, navItem)} onClick={() => onClickItem('shop')}>
        {t(`mainNav.shop`, {
        ns: 'navigation'
      })}
      </button>
      <LocalizedLink href={t(`preOwnedWatches`, {
      ns: 'navigation'
    })}>
        <a className={cx(linkStyle, navItem)}>Pre-owned</a>
      </LocalizedLink>
      {SHOULD_DISPLAY_RCPO_CONTENT && <LocalizedLink href={t(`rcpo.index.url`, {
      ns: 'navigation'
    })}>
          <a className={cx(linkStyle, navItem)}>Rolex Certified Pre Owned</a>
        </LocalizedLink>}
      <button className={cx(linkStyle, navItem)} onClick={() => onClickItem('diamonds')}>
        {t(`mainNav.diamonds`, {
        ns: 'navigation'
      })}
      </button>
      <button className={cx(linkStyle, navItem)} onClick={() => onClickItem('house-of-gassan')}>
        {t(`mainNav.houseOfGassan`, {
        ns: 'navigation'
      })}
      </button>
      <LocalizedLink href={t(`tours`, {
      ns: 'navigation'
    })}>
        <a className={cx(linkStyle, navItem)}>Tours</a>
      </LocalizedLink>
    </div>;
};
const parent = parse({
  display: {
    _: 'none',
    large: 'flex'
  }
}, "prco5ht");
const navItem = parse({
  display: 'inline-block'
}, "n10w28ij");

require("./MainNav.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./MainNav.tsx");