import { parse } from '@config/theme';
export const container = parse({
  py: '3',
  width: '100%',
  position: 'absolute',
  bg: 'pampasLight'
}, "cgkr3ed");
export const item = parse({
  display: 'block',
  py: '3',
  px: '4'
});

require("./SubNav.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SubNav.styles.ts");