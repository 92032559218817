export function IconMapMarker() {
  return (
    <>
      <path
        stroke="currentColor"
        stroke-width="2"
        d="M20 10c-4.418 0-8 3.114-8 6.956 0 2.168 3 6.544 4.765 8.694C18.259 27.47 20 29 20 29s1.777-1.5 3.235-3.35C24.692 23.8 28 19.124 28 16.956 28 13.115 24.418 10 20 10Z"
      />
      <path fill="currentColor" d="m20 14 3 3-3 3-3-3 3-3Z" />
    </>
  )
}
