export function AccountHeart() {
  return (
    <>
      <path
        fill="currentColor"
        className="show-on-small"
        d="M28.517 18.197l-.595-.19a.634.634 0 00-.015.055l.61.135zm-7.893 8.053l-.344.522.344.226.344-.226-.344-.522zm-7.911-8.122l.61-.134a.631.631 0 00-.016-.06l-.594.194zm1.998-5.093l-.301-.547-.003.001.303.546zm5.372 1.024l.482-.398-.482.398zm.542.657l-.482.398a.625.625 0 00.965 0l-.483-.398zm.542-.657l-.482-.398.482.398zm5.368-1.024l.302-.546v-.001l-.302.547zm2.215 3.77l.625.002v-.003h-.625zm-.843 1.257c-.124.562-.487 1.23-1.053 1.968-.56.728-1.284 1.479-2.073 2.204-1.578 1.449-3.37 2.75-4.5 3.494l.687 1.044c1.17-.77 3.018-2.111 4.659-3.618.82-.753 1.6-1.558 2.218-2.363.611-.796 1.1-1.636 1.283-2.46l-1.22-.27zm-6.94 7.666c-1.13-.745-2.923-2.045-4.503-3.503-.79-.728-1.515-1.486-2.077-2.223-.568-.745-.935-1.428-1.064-2.008l-1.22.27c.185.837.675 1.69 1.29 2.496.62.814 1.402 1.626 2.224 2.384 1.643 1.516 3.493 2.858 4.663 3.628l.688-1.044zm-7.66-7.793a3.64 3.64 0 01-.182-1.13h-1.25c0 .514.083 1.025.244 1.517l1.188-.387zm-.182-1.13c0-1.344.726-2.577 1.89-3.223l-.608-1.093a4.935 4.935 0 00-2.532 4.315h1.25zm1.886-3.222a3.698 3.698 0 011.77-.458v-1.25c-.821 0-1.643.213-2.371.613l.601 1.095zm1.77-.458c1.09 0 2.118.484 2.82 1.333l.964-.797a4.902 4.902 0 00-3.785-1.786v1.25zm2.82 1.332l.542.657.964-.796-.542-.657-.964.796zm1.507.656l.54-.656-.964-.795-.541.656.965.795zm.54-.655c1.097-1.325 3.07-1.71 4.585-.875l.603-1.095c-2.044-1.127-4.674-.61-6.15 1.173l.962.797zm4.584-.876a3.679 3.679 0 011.893 3.222h1.25c0-1.794-.97-3.448-2.538-4.315l-.605 1.093zm1.893 3.22c-.002.378-.061.76-.203 1.205l1.19.38c.177-.554.26-1.062.263-1.58l-1.25-.006z"
      ></path>
      <path
        fill="currentColor"
        className="hide-on-small"
        d="M29.728 18.888l-.956-.294a1.025 1.025 0 00-.022.085l.978.209zm-9.23 9.737l-.562.827.563.383.563-.383-.563-.827zm-9.25-9.82l.979-.209a.995.995 0 00-.024-.091l-.954.3zm2.337-6.158l-.494-.87-.005.003.499.867zm6.281 1.238l.782-.624-.782.624zm.634.794l-.781.624a1 1 0 001.563 0l-.782-.624zm.633-.794l-.78-.625-.002.002.782.623zm6.277-1.238l.497-.868h-.002l-.495.868zM30 17.204l1 .004v-.004h-1zm-1.25 1.475c-.136.634-.538 1.41-1.19 2.29-.643.864-1.479 1.761-2.395 2.631-1.832 1.74-3.915 3.304-5.229 4.198l1.125 1.654c1.377-.938 3.55-2.568 5.481-4.402.966-.916 1.888-1.9 2.623-2.889.724-.975 1.318-2.023 1.54-3.064l-1.955-.418zm-7.689 9.12c-1.314-.895-3.397-2.459-5.231-4.208-.917-.875-1.754-1.78-2.4-2.655-.654-.889-1.063-1.683-1.203-2.34l-1.956.419c.226 1.057.821 2.119 1.55 3.107.736 1 1.661 1.993 2.629 2.916 1.934 1.845 4.11 3.477 5.486 4.414l1.125-1.654zm-8.858-9.294A4.318 4.318 0 0112 17.204h-2c0 .646.1 1.285.295 1.902l1.908-.601zM12 17.204c0-1.552.811-2.959 2.083-3.69l-.997-1.734C11.171 12.881 10 14.963 10 17.204h2zm2.078-3.687A3.925 3.925 0 0116.005 13v-2c-1.012 0-2.021.27-2.914.778l.987 1.739zM16.005 13c1.182 0 2.306.541 3.08 1.51l1.562-1.25C19.504 11.832 17.812 11 16.005 11v2zm3.08 1.51l.634.793 1.563-1.248-.634-.794-1.563 1.248zm2.197.792l.633-.794-1.564-1.246-.633.794 1.564 1.246zm.632-.792c1.205-1.505 3.358-1.93 5-.994l.99-1.738c-2.519-1.435-5.747-.77-7.551 1.482l1.56 1.25zm5-.995c1.275.73 2.086 2.138 2.086 3.689h2c0-2.242-1.172-4.325-3.093-5.425l-.994 1.736zM29 17.2a4.693 4.693 0 01-.228 1.394l1.912.588A6.688 6.688 0 0031 17.208l-2-.008z"
      ></path>
    </>
  )
}
