import { parse } from '@config/theme';
export const link = parse({
  fontFamily: 'body',
  alignItems: 'center',
  color: 'bigStone'
}, "lptxet8");
export const preHeader = parse({
  display: {
    _: 'none',
    large: 'block'
  },
  width: '100%',
  bg: 'pampasLight'
}, "prc20l1");
export const container = parse({
  display: 'flex',
  height: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center'
}, "cjp4dp");

require("./PreHeader.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./PreHeader.styles.ts");